<template>
  <el-scrollbar>
    <div class="garD_body_all">
      <div style="width: 100%">
        <el-page-header title="返回" content="菜园详情" @back="goBack" />
      </div>
      <div
        style="
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-top: 20px;
          padding-bottom: 10px;
          border-bottom: 2px solid #f2f2f2;
        "
      >
        <div style="display: flex; align-items: center; margin-left: 20px">
          菜园IP：
          <div style="color: #409eff">{{ data.static_ip }}</div>
        </div>
        <div class="garD_author">
          <img :src="imgSrc" style="width: 50px; height: 50px" alt="" />
          <div class="garD_author_text">
            <div class="garD_time">
              <div style="color: #409eff">所属人：</div>
              <div>{{ data.user_name }}</div>
            </div>
            <div class="garD_time">
              <div style="color: #409eff">ID：</div>
              <div>{{ data.user_id }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          width: 100%;
          justify-content: space-around;
          margin-top: 20px;
          padding-bottom: 10px;
          border-bottom: 2px solid #f2f2f2;
          align-items: center;
        "
      >
        <div style="display: flex; align-items: center">
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div v-for="fit in fits" :key="fit">
              <el-image
                style="width: 140px; height: 140px"
                :src="url"
                :fit="fit"
              ></el-image>
            </div>
            <div>山竹</div>
          </div>
          <div style="margin: -30px 0px 0px 40px; line-height: 40px">
            <div style="display: flex; align-items: center">
              风扇：
              <div style="color: #409eff">正常</div>
            </div>
            <div style="display: flex; align-items: center">
              光照：
              <div style="color: #c00909">异常</div>
            </div>
            <div style="display: flex; align-items: center">
              水泵：
              <div style="color: #409eff">正常</div>
            </div>
          </div>
        </div>
        <div style="display: flex">
          <div style="line-height: 40px">
            <div style="display: flex; align-items: center">
              种植天数：
              <div style="color: #409eff">{{ data.pt_days }}</div>
              天
            </div>
            <div style="display: flex; align-items: center">
              环境温度：
              <div style="color: #409eff">{{ data.airh }}</div>
              (℃)
            </div>
            <div style="display: flex; align-items: center">
              土壤湿度：
              <div style="color: #409eff">{{ data.airt }}</div>
              (%)
            </div>
            <div style="display: flex; align-items: center">
              发布种植日记：
              <div style="color: #409eff">20</div>
              篇
            </div>
          </div>
          <div style="line-height: 40px; margin-left: 30px">
            <div style="display: flex; align-items: center">
              环境湿度：
              <div style="color: #409eff">{{ data.airt }}</div>
              (hPa)
            </div>
            <div style="display: flex; align-items: center">
              光照总量：
              <div style="color: #409eff">{{ data.light }}</div>
              (K/LUX)
            </div>
            <div style="display: flex; align-items: center">
              PH值：
              <div style="color: #409eff">{{ data.ph }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div class="garD_btn">
          <div class="garD_title">种植记录</div>
        </div>
        <div style="width: 90%; margin-top: 10px">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: end;
              margin-bottom: 10px;
            "
          >
            <el-select
              style="width: 150px"
              v-model="value"
              class="m-2"
              placeholder="全部"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <el-table :data="data.ptainfo1" height="451px" style="width: 100%">
            <el-table-column prop="name" label="种植名称" />
            <el-table-column prop="user_id" label="用户id" />
            <el-table-column prop="pt_date" label="开始种植时间" />
            <el-table-column prop="time" label="结束种植时间" />
            <el-table-column fixed="right" label="操作" width="120px">
              <template #default="scope">
                <div style="display: flex">
<!--                  <el-button-->
<!--                    type="primary"-->
<!--                    size="small"-->
<!--                    @click.prevent="editRow(scope.$index)"-->
<!--                  >-->
<!--                    查看-->
<!--                  </el-button>-->
                  <el-button
                    type="danger"
                    size="small"
                    @click.prevent="deleteRow(scope.row)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="demo-pagination-block">
          <el-pagination
            v-model:currentPage="currentPage4"
            :page-sizes="[10, 20, 30, 40]"
            v-model:page-size="data.page_size"
            v-model:current-page="data.page"
            layout="total, sizes, prev, pager, next, jumper"
            :total="data.ptainfo.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import { ref, getCurrentInstance, reactive } from "vue";
import { onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "gardenDetails",
  setup() {
    const { proxy } = getCurrentInstance();
    const fits = ["scale-down"];
    const data = reactive({
      static_ip: "",
      user_name: "",
      user_id: 1,
      photosensitive: 1,
      pt_days: 1,
      airh: 1,
      airt: 1,
      light: 1,
      ph: 7,
      ptainfo1: [],
      ptainfo: [],
      page: 1,
      page_size: 10,
      name: "",
      pt_date: "",
      time: "",
    });
    const imgSrc = require("../assets/zyhylogo.png");
    const url = require("../assets/background.png");
    const currentPage4 = ref(4);
    const value = ref("");
    const options = [
      {
        value: "全部",
        label: "全部",
      },
      {
        value: "成功",
        label: "成功",
      },
      {
        value: "失败",
        label: "失败",
      },
    ];
    onMounted(() => {
      if (proxy.$route.query.static_ip != undefined) {
        data.static_ip = proxy.$route.query.static_ip;
        articleVInfo();
        ptadatainfo();
      }
    });
    function getPageData() {
      let start = (data.page - 1) * data.page_size;
      let end = start + data.page_size;
      data.ptainfo1 = data.ptainfo.slice(start, end);
    }
    function articleVInfo() {
      proxy.$http
        .get("/PtdataviewController/queryInfoPta", {
          static_ip: data.static_ip,
        })
        .then((res) => {
          console.log(res);
          data.user_name = res.data[0].user_name;
          data.static_ip = res.data[0].static_ip;
          data.photosensitive = res.data[0].photosensitive;
          data.pt_days = res.data[0].pt_days;
          data.airt = res.data[0].airt;
          data.airh = res.data[0].airh;
          data.light = res.data[0].light;
          data.user_id = res.data[0].user_id;
          console.log(data.user_name);
        });
    }
    function ptadatainfo() {
      proxy.$http
        .get("/PtdataviewController/queryInfoPta", {
          static_ip: data.static_ip,
        })
        .then((res) => {
          console.log(res.data);
          data.ptainfo = res.data;
          getPageData(); //分页
        });
    }
    function goBack() {
      proxy.$router.go(-1);
    }
    function editRow(index) {
      console.log(index);
      proxy.$router.push("/plantinRecords");
    }
    function deleteRow(row) {
      console.log(row);
      ElMessageBox.confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          proxy.$http
            .get("/PtdataviewController/deletePta", {
              p_id: row.p_id,
            })
            .then((res) => {
              console.log(res);
              if (res.code === 1) {
                ElMessage({
                  type: "success",
                  message: "删除成功",
                });
                ptadatainfo();
              } else
                ElMessage({
                  type: "error",
                  message: "删除失败",
                });
            });
        })
        .catch(() => {
          ElMessage({
            type: "error",
            message: "撤销操作",
          });
        });
    }
    function handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      data.page_size = val;
      getPageData();
    }
    function handleCurrentChange(val) {
      data.page = val;
      // console.log(val);
      getPageData();
    }
    return {
      data,
      imgSrc,
      url,
      fits,
      currentPage4,
      value,
      options,
      ptadatainfo,
      articleVInfo,
      goBack,
      editRow,
      deleteRow,
      handleSizeChange,
      handleCurrentChange,
      getPageData,
    };
  },
};
</script>

<style>
.garD_body_all {
  background-color: white;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.garD_time {
  display: flex;
}
.garD_author {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.garD_author_text {
  margin-left: 10px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.garD_title {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 2px;
  margin: 20px 20px 10px 20px;
}
.garD_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.demo-pagination-block {
  margin-top: 10px;
}
/*隐藏滚动条*/
.el-table__body-wrapper {
  padding-right: 20px;
}
.artV_body_all {
  background-color: white;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.artV_title {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
}
.artV_time {
  display: flex;
}
.artV_author {
  display: flex;
  align-items: center;
}
.artV_author_text {
  margin-left: 10px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.artV_info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #f2f2f2;
}
.artV_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.artV_content {
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
  width: 95%;
}
.artV_demo-image {
  display: flex;
  flex-flow: wrap;
}
.actV_block {
  margin: 0 10px -20px 10px;
}
.artV_bottom {
  display: flex;
  justify-content: end;
}
</style>
